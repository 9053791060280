import {onMounted, ref} from "vue"
import $api from "@/api"
export default function useCompanyTop(){
    const topList=ref([])
    const getCompanyTop=async()=>{
        const [err,data]=await $api.company.getCompanyTop({Number:5})
        if(!data.succeeded) return ;
        topList.value=data.data
    }
    onMounted(getCompanyTop)
    return {
        topList
    }
}