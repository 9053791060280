import { onMounted,reactive,ref } from "vue"
import $api from "@/api"

export default function useAllCompany(classItems){
    const queryParams=reactive({
        CompanyName:"",
        PageIndex:1,
        PageSize:15,
        TypeId:"-1",
        States:"-1"
    })
    const companyData=reactive({
        list:[],
        totalCount:0
    })
    const getCompanyList=async(typeId="-1",keywords="",states="")=>{
        if (!!typeId) {
            queryParams.TypeId = typeId
            classItems.value.map(e => {
                e.value === typeId ? e.active = true : e.active = false;
                return e
            })
        }
        if(states===queryParams.States){
            queryParams.States="-1"
        }else if(!!states){
            queryParams.States=states;
        }
        queryParams.CompanyName=keywords
        const [err,data]=await $api.company.getCompanyList(queryParams)
        if(!data.succeeded) return;
        companyData.list=data.data.items
        companyData.totalCount=data.data.totalCount
    }


    onMounted(getCompanyList)
    return {
        queryParams,
        companyData,
        getCompanyList
    }
}