<template>
  <div class="inner_banner">
    <a class="db" href="javascript:void(0);"
      ><img :src="require('@/assets/images/ee.png')"
    /></a>
  </div>
  <main class="ztb_main policy_main">
    <div class="ztb_box">
      <class-items :classifies="classifies" @callback="getCompanyList">
        <div>
          <span
            class="free dib"
            :class="[queryParams.States === '1' ? 'bg-gold' : 'bg-ccc']"
            @click="getCompanyList('', queryParams.CompanyName, '1')"
            >已认证</span
          >
          <span
            class="pay dib"
            :class="[queryParams.States === '2' ? 'bg-gold' : 'bg-ccc']"
            @click="getCompanyList('', queryParams.CompanyName, '2')"
            >未认证</span
          >
        </div>
      </class-items>
      <div class="company-container">
        <ul class="ent_list company-list">
          <li
            v-for="item in companyData.list"
            :key="item.id"
            class="company-item"
            @click="showDetail(item.id)"
          >
            <div class="img_box auto">
              <img :src="getImgUrl(item.companyLogo)" />
            </div>
            <div class="ent_name auto">{{ item.companyName }}</div>
            <div class="icon_box cf auto">
              <i class="iconfont icon-icon-county-province fl"></i>
              <span class="fl mr10">{{ item.companyAddress }}</span>
              <i
                class="iconfont icon-renzheng fl verify"
                v-if="item.isFlag"
              ></i>
              <span class="fr ml5">{{ item.articleNumber }}</span>
              <i class="iconfont icon-list fr"></i>
            </div>
          </li>
        </ul>
      </div>

      <div class="pagnation">
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="queryParams.PageIndex"
          :page-sizes="[5, 10, 15, 20]"
          :page-size="queryParams.PageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="companyData.totalCount"
        >
        </el-pagination>
      </div>
    </div>
    <div class="ztb_latest">
      <div class="sidebar_box">
        <div class="title">
          <span>厂商资讯</span>
        </div>
        <ul>
          <li v-for="item in topList" :key="item.id" class="top-company-item">
            <router-link
              :to="{ path: '/newsDetail', query: { id: item.id } }"
              >{{ item.title }}</router-link
            >
          </li>
        </ul>
      </div>
    </div>
  </main>
</template>
<script setup>
import { useRouter } from "vue-router";
import useInfoTree from "@/composable/universal/useInfoTree";
import usePagination from "@/composable/universal/usePagination";
import useAllCompany from "@/composable/company/useAllCompany";
import useCompanyTop from "@/composable/company/useCompanyTop";
import { getImgUrl } from "@/utils/imageRelated";
import ClassItems from "@/components/classItems";

const router = useRouter();
const { classifies } = useInfoTree("15");
const { queryParams, companyData, getCompanyList } = useAllCompany(classifies);
const { handleSizeChange, handleCurrentChange } = usePagination( queryParams,getCompanyList);
const { topList } = useCompanyTop();
const showDetail = (id) => {
  router.push({
    name: "CompanyDetail",
    query: { id },
  });
};
</script>
<style lang="less" scoped>
.company-list {
  display: grid !important;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 240px);
  grid-gap: 10px;
}
.pagnation {
  text-align: center;
}
.bg-gold {
  color: white !important;
  background: #cc9736 !important;
}
.bg-ccc {
  background: #ccc !important;
}
.pay {
  color: black;
}
.pay:hover {
  color: #cc9736;
}
.top-company-item,
.company-item {
  cursor: pointer;
}
.top-company-item > a:hover {
  color: #409eff;
}
</style>
